import React from "react";
import Layout from "./src/components/Layout";

export const wrapPageElement = props => (
    <Layout>
        { props.element }
    </Layout>
);

/*
 * This can help you if you don't always want to update scroll. Say, for example, you have a filter
 * one one page, that will change the URL, while staying on the same page. In that case you can define
 * here when the scroll should be updated.
 */
// export const shouldUpdateScroll = ({ prevRouterProps, routerProps}) => {
//     if (prevRouterProps.location.pathname.includes("search") && routerProps.location.pathname.includes("search"))
//         return false;
// 
//     return true;
// };


import React from "react";
import { Link } from "gatsby";

const NavItem = props => {
    return (
        <li className="nav-item">
            <Link onClick={ props.onClick } className="nav-link" to={ props.href } >
                { props.text }
            </Link>
        </li>
    );
};

export default NavItem;

module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"","short_name":"","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"standalone","icon":"./static/images/favicon.png","orientation":"portrait-primary","lang":"nl-NL"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

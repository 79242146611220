import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

import "../../scss/main.scss";

const Index = props => {
    return (
        <>
        <Navbar />
            <main>
                { props.children }
            </main>
        <Footer />
        </>
    );
};

export default Index;

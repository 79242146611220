import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    info: markdownRemark ( frontmatter: { identifier: { eq: "companyInformation" } } ) {
        frontmatter {
            companyName
            companyAddress
            companyZip
            companyCity
            companyPhone
            companyEmail
        }
    }
}`);

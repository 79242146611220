import React from "react";

export default props => (
    <div className="cross" onClick={ props.onClick }>
        <div className="cross__cross">
            <div className="cross__bar"></div>
            <div className="cross__bar"></div>
        </div>
        <div className="cross__square"></div>
    </div>
);

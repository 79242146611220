import React from "react";
import { Link } from "gatsby";
import Logo from "../Logo";
import Obfuscate from "react-obfuscate";
import Phone from "../svg/phone";
import Mail from "../svg/mail";

import getCompanyInfo from "../../queries/getCompanyInfo";

export default props => {

    const { info: { frontmatter: company } } = getCompanyInfo();

    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__grid">

                    <div className="footer__address">
                        <p>
                            { company.companyAddress }<br />
                            { company.companyZip } { company.companyCity }
                        </p>
                    </div>

                    <div className="footer__logos">
                        <Logo />
                        <img src="/images/logo-spuistraat.png" alt="Spuistraat 10 logo" />
                    </div>

                    <div className="footer__contact">
                        <p>
                            <Phone color="#115194"/><Obfuscate tel={ company.companyPhone } /><br />
                            <Mail color="#115194"/><Obfuscate email={ company.companyEmail } />
                        </p>
                    </div>

                </div>

                <div className="footer__legal">
                    <a className="footer__conditions" title="Algemene voorwaarden" target="_blank" href="https://www.spuistraat10.nl/algemene-voorwaarden/">Algemene Voorwaarden</a>
                    <p className="footer__separator">|</p>
                    <Link className="footer__privacy" to="/privacy">Privacybeleid</Link>
                    <p className="footer__separator">|</p>
                    <p className="footer__credits">Website by&nbsp;<a href="https://bttrcode.nl" _target="blank">Bttr::code</a></p>
                </div>


                <div className="footer__copyright">
                    <p>Copyright&copy; Straver Legal 2020</p>
                </div>

            </div>
        </footer>
    );
}
